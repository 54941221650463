<template>
	<div class="app-container">
		<el-card>
			<div class="info">
				<span>院系：</span>
				<el-select v-model="depid" placeholder="请选择" clearable @change="changeSelect(depid)">
					<el-option v-for="item in schoolSystemList" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
				<span>班级：</span>
				<el-select v-model="did" placeholder="请选择" clearable>
					<el-option v-for="item in systemList" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
				<span>时间：</span>
				<div>
					<el-date-picker v-model="searchDate" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" @change="pickchange"> </el-date-picker>
				</div>
				<div>
					<el-button type="primary" @click="goSearch()">查询</el-button>
				</div>
			</div>

			<div class="flex-row">
				<!-- 云图 -->
				<div class="wordcloud" id="wordcloud" v-show="ifShow_wordcloud"></div>
				<div class="wordcloud" id="wordcloud_none" v-show="ifShow_wordcloud == false">
					<div style="text-align:center;font-size:15px;color:gray;padding-top:150px;">暂无云词数据</div>
				</div>

				<!-- 表格 -->
				<el-table id="vcfResult" class="wordcloudRight45" ref="multipleTable" :data="studentList"
					tooltip-effect="dark" style="width: 100%" element-loading-text="Loading" border fit
					highlight-current-row>
					<!-- <el-table-column prop="id" align="center" label="序号" width="60px"></el-table-column> -->
					<el-table-column type="index" width="50px" align="center" label="序号"></el-table-column>
					<el-table-column prop="name" align="left" label="姓名">
						<template slot-scope="scope">
							<router-link :to="{
								path: 'studentIndexSetting',
								query: {
									studentid: scope.row.id,
									studentname: scope.row.name,
								},
							}"></router-link>
							{{ scope.row.name }}
						</template>
					</el-table-column>
					<el-table-column prop="studentnum" align="left" label="学号"></el-table-column>
					<el-table-column prop="totalBonus" align="center" label="当月总加分" width="120px"></el-table-column>
					<el-table-column prop="totalDeduction" align="center" label="当月总扣分" width="120px"></el-table-column>
					<el-table-column prop="fractionnum" align="center" label="最终得分" width="120px"></el-table-column>
				</el-table>
			</div>

			<div class="mgt24 align-center">
				<el-button-group>
					<el-button v-for="(item, index) in tab" :key="index" :class="index == current ? 'active' : ''"
						@click="tabs(index)">{{ item }}</el-button>
				</el-button-group>
				<el-button type="primary" style="float: right;" @click="exportExcel">数据导出</el-button>
			</div>

			<div class="canvasBox">
				<!-- 折线图 -->
				<div class="test1">
					<div v-show="ifShow_line_chart" id="myChart2"></div>
					<div v-show="ifShow_line_chart == false"
						style="text-align:center;font-size:15px;color:gray;padding-top:150px;">暂无折线图数据</div>
				</div>

				<!-- 饼图 -->
				<!-- <div class="echarts-list">
					<div id="myChart3" class="echarts-pieChart"></div>
					<div id="myChart4" class="echarts-pieChart"></div>
				</div> -->

				<!-- 饼图 -->
				<div class="echarts-list">
					<div class="echarts-pieChart" v-for="(item, index) in piedata" :key="index">
						<!-- 班级、学生饼图 -->
						<pieEcharts v-if="flag2" :piedata="piedata" :dataPie="dataPie" :index="index" />
					</div>
				</div>

			</div>

			<el-dialog v-if="dialogFormVisible" :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible"
				:before-close="handleClose" width="50%" minWidth="600px">
				<addComponent ref="dialogForm" @changeDialogFormVisible="changeDialogFormVisible($event)"
					:dialog-status="dialogStatus" :dialogStatus="dialogStatus" />
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
// import { getList, getBrigade, getRole, putRole } from "../../api/users.js";
import * as api from "@/api/users";
import * as echarts from "echarts";
import addComponent from "./addComponent.vue";
import pieEcharts from "../echartsComponent/pie_echarts.vue";

export default {
	components: {
		addComponent,
		pieEcharts
	},
	data() {
		return {
			flag2: false,
			typeOptions: [],
			page: 1,
			pageSize: 10,
			total: 1000,
			did: '',
			depid: '',
			didselect: "",
			value1: "",
			month: {},
			threeMonth: {},
			oneYear: {},

			current: 0,
			tab: ["日", "周", "月"],
			systemList: [],
			schoolSystemList: [],
			searchDate: [], //默认时间
			form: {},
			idis: "",
			formLabelWidth: "120px",
			Authorization: "",
			dialogFormVisible: false,
			dialogStatus: "",
			textMap: {
				update: "编辑",
				create: "新增",
				view: "详情",
			},

			listQuest: {
				page: 1,
				pageSize: 10,
			},
			roleList: [],
			xDataList: [],
			yDataList: [],
			options: [{
				label: "有",
				value: 1,
			},
			{
				label: "没有",
				value: 0,
			},
			],
			roles: [],
			tableData: [],
			input: "",
			namelist: [], // [{"type": "line",  "data": [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1.1, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ], "xdate":....
			dataline: [], // null
			xdatelist: [], // x轴 坐标系  "2022-04-12", "2022-04-13", "2022-04-14",
			studentList: [],

			// --------- 云词 --------- 
			wordcloudList: [],
			// 是否显示 云词
			ifShow_wordcloud: true,
			// 云词 obj
			wordcloud_chart: null,

			// --------- 折线图 ---------
			line_chart: null, // 空对象
			// 是否显示 折线图
			ifShow_line_chart: true,

			// --------- 饼图 --------- 
			pie1_chart: null, // 空对象
			pie2_chart: null, // 空对象
			dataPie: [], //班级指标
			dataPie2: [], //学生指标
			piedata: [],

		};
	},
	computed: {
		// 折线图
		getoption() {
			let that = this;
			let option = {
				title: {
					text: "考核得分情况", // 主标题
					subtext: "", // 副标题
					x: "center", // x轴方向对齐方式
					y: "8%",
				},
				tooltip: {
					trigger: "axis", // axis   item   none三个值
				},
				xAxis: {
					type: "category",
					data: that.xdatelist[0],
					name: "",
					axisLabel: {
						interval: 0,
						rotate: 38,
					},
				},
				yAxis: {
					type: "value",
					name: "",
				},
				legend: {
					orient: "horizontal",
					x: "center",
					y: "top",
					top: 20,
					data: that.namelist,
				},
				series: that.dataline,
			};
			return option;
		},

		getoption1() {
			let dataPie = this.dataPie;
			let sum = 0;
			for (let i in dataPie) {
				sum = sum + parseFloat(Math.abs(dataPie[i].value));
			}
			let that = this;
			let option = {
				title: [{
					text: "班级考核得分", // 主标题
					x: "19%",
				},],
				tooltip: {
					trigger: "item",
					formatter(e) {
						let item = e.name.split(",");
						return item[0] + " :   " + item[1];
					},
				},
				legend: [{
					orient: "vertical",
					left: "50%", // 表格的位置
					y: "center",
					formatter(e) {
						let item = e.split(",");
						if (undefined == item[1]) {
							return "-暂无数据-";
						} else {
							const percent = (parseFloat(Math.abs(item[1])) / parseFloat(sum)) * 100;
							return (item[0] + "    " + item[1] + "    " + percent.toFixed(2) + "%");
						}
					},
				},],
				series: [{
					name: that.pieName,
					type: "pie",
					radius: ["40%", "70%"],
					center: ["25%", "50%"], // 图形：x轴、y轴的位置
					avoidLabelOverlap: false,
					label: {
						show: false,
						position: "center",
						color: '#9A9EBA',
						formatter: '-暂无数据-',
						fontSize: "16px"
					},
					emphasis: {
						label: {
							show: false,
						}
					},
					labelLine: {
						show: false,
					},
					data: that.dataPie,
				},],
			};
			return option;
		},

		getoption2() {
			let dataPie2 = this.dataPie2;
			let sum = 0;
			for (let i in dataPie2) {
				sum = sum + parseFloat(Math.abs(dataPie2[i].value));
			}
			let that = this;
			let option = {
				title: [{
					text: "学生考核得分", // 主标题
					x: "19%",
				},],
				tooltip: {
					trigger: "item",
					formatter(e) {
						let item = e.name.split(",");
						return item[0] + " :   " + item[1];
					},
				},
				legend: [{
					orient: "vertical",
					left: "50%", // 表格的位置
					// right: "10%",
					y: "center",
					formatter(e) {
						let item = e.split(",");
						const percent =
							(parseFloat(Math.abs(item[1])) / parseFloat(sum)) * 100;
						return (
							item[0] + "    " + item[1] + "    " + percent.toFixed(2) + "%"
						);
					},
				},],
				series: [{
					name: that.pieName,
					type: "pie",
					radius: ["40%", "70%"],
					center: ["25%", "50%"], // x轴、y轴的位置
					avoidLabelOverlap: false,
					label: {
						show: false,
						position: "center",
					},
					// 中间大字
					// emphasis: {
					//   label: {
					//     show: true,
					//     fontSize: "40",
					//     fontWeight: "bold",
					//   },
					// },
					labelLine: {
						show: false,
					},
					data: that.dataPie2,
				},],
			};
			return option;
		},
	},

	created() {
		this.loadData();
		this.getSchoolSystemList();
	},
	mounted() {
		this.searchparment();
		this.drawLine1();
		//this.drawLine2();
		//this.drawLine3();
		this.showWordCloud({
			startTime: "",
			endTime: "",
			depId: this.depid,
			classId: this.did,
		});
	},
	methods: {
		// 词云
		showWordCloud(params) {
			api.groupByClassId(params).then((res) => {
				this.wordcloudList = res.data;
				this.wordcloud();
			});
		},
		wordcloud() {
			// 基于准备好的dom，初始化echarts实例
			let objTemp = document.getElementById("wordcloud");
			// 先销毁
			// if (this.wordcloud_chart != null && this.wordcloud_chart != "" && this.wordcloud_chart != undefined) {
			//   this.wordcloud_chart.dispose(); // 销毁
			//   this.wordcloud_chart = null;
			// }
			// 再创建
			if (this.wordcloud_chart == null) {
				this.wordcloud_chart = echarts.init(objTemp);
			}
			if (this.wordcloudList != null && this.wordcloudList.length > 0) {
				this.ifShow_wordcloud = true;
				// 绘制图表
				this.wordcloud_chart.setOption({
					backgroundColor: "#ffffff",
					tooltip: {
						show: true
					},
					series: [{
						name: "热点分析", //数据提示窗标题
						type: "wordCloud",
						drawOutOfBound: true,
						sizeRange: [10, 38], // 设置字符大小范围
						rotationRange: [-10, 20], // 数据翻转范围
						rotationRange1: [-25, 10], // 数据翻转范围
						//shape: "circle", //形状
						textPadding: 0,
						width: "100%",
						gridSize: 3,
						autoSize: {
							enable: true,
							minSize: 6,
						},
						textStyle: {
							color: () => {
								return (
									"rgb(" + [
										Math.round(Math.random() * 255),
										Math.round(Math.random() * 255),
										Math.round(Math.random() * 255),
									].join(",") +
									")"
								);
							},
							emphasis: {
								shadowBlur: 10,
								shadowColor: "#333",
							},
						},
						data: this.wordcloudList,
					},],
				});
			} else {
				this.ifShow_wordcloud = false;
			}
		},
		// 院系 列表
		getSchoolSystemList() {
			let params = {
				page: 1,
				pageSize: 10,
			};
			api.getSystemList(params).then((res) => {
				this.schoolSystemList = res.data.records;
			});
			this.changeSelect();
		},

		/**
		 * 获取班级、学生饼图
		 */
		async goNewSearchclass() {
			let params = {
				startTime: this.searchDate != null && this.searchDate.length > 0 ? this.$moment(this
					.searchDate[0]).format("YYYY-MM-DD") : "",
				endTime: this.searchDate != null && this.searchDate.length > 0 ? this.$moment(this.searchDate[
					1]).format("YYYY-MM-DD") : "",
				classId: this.did,
				depId: this.depid,
				sourceType: "faculty",
				starts: "1",
			};
			let mPieData = await api.getFacultyStatistics(params);
			let datas = mPieData.data;
			// 新数组
			let arr = [];
			for (let i = 0; i < datas.length; i++) {
				let pie = (datas[i].type = "pie");
				let dataList = (datas[i].data = datas[i].dataList);
				let cname = "";
				if (typeof (datas[i].name) != "undefined") cname = datas[i].name;
				if (typeof (datas[i].cname) != "undefined") cname = datas[i].cname;
				if (typeof (cname) == "undefined") cname = "";
				let obj = {
					type: pie,
					name: cname,
					data: dataList,
				};
				arr.push(obj);
			}

			// 饼图数据
			let piedatas = [];
			arr.forEach((item) => {
				if (item.data.length <= 0) {
					piedatas.push(item);
				} else {
					piedatas.push(item.data);
				}
			});

			let piearr = [];
			for (let i = 0; i < piedatas.length; i++) {
				let dataPie = piedatas[i].map((item) => {
					return {
						// 解决 0 NAN%
						value: Math.abs(item.score == 0 ? 1 : item.score),
						name: item.category + "," + (item.score == 0 ? 1 : item.score),
					};
				});
				if (dataPie.length == 1 && dataPie[0].name == ',1' && 1 == dataPie[0].value) {
					piearr.push([]);
				} else {
					piearr.push(dataPie);
				}
			}
			this.dataPie = piearr;
			this.piedata = arr;
			this.flag2 = true;
		},

		// 饼图查询
		goSearchclass() {
			let params = {
				classId: this.did,
				depId: this.depid,
				starttime: this.searchDate != null && this.searchDate.length > 0 ? this.$moment(this.searchDate[0])
					.format("YYYY-MM-DD") : "",
				endtime: this.searchDate != null && this.searchDate.length > 0 ? this.$moment(this.searchDate[1])
					.format("YYYY-MM-DD") : "",
				sourceType: "",
				starts: "1",
			};
			let params1 = {
				classId: this.did,
				depId: this.depid,
				startTime: this.searchDate != null && this.searchDate.length > 0 ? this.$moment(this.searchDate[0])
					.format("YYYY-MM-DD") : "",
				endTime: this.searchDate != null && this.searchDate.length > 0 ? this.$moment(this.searchDate[1])
					.format("YYYY-MM-DD") : "",
				sourceType: "",
				starts: "1",
			};
			// 班级指标
			api.piechartclass(params).then((res) => {
				let {
					data
				} = res;
				let datas = data ? data.map((item) => {
					return item.dataList.map((it) => {
						return {
							// 解决 0 NAN%
							value: Math.abs(it.score == 0 ? 1 : it.score),
							name: it.category + "," + (it.score == 0 ? 1 : it.score),
						};
					});
				}) : [];
				this.dataPie = datas[0];
				console.log("班级数据 === 你是什么 ===", this.dataPie, datas[0]);
				// if(null == datas[0] || datas[0].length <= 0){
				// 	// 无数据时：展示暂无数据
				// 	const dom = document.getElementById("myChart3");
				// 	dom.innerHTML = '-暂无相关数据-';
				// 	dom.style.cssText = 'text-align:center; color: #999; border: none;line-height: 300px';
				// 	dom.removeAttribute('_echarts_instance_');
				// }else{
				this.drawLine2();
				// }
			});
			// 学生指标
			api.depOrClass(params1).then((res) => {
				let {
					data
				} = res;
				let datas = (data && data.length > 0) ? data[0].dataList.map((item) => {
					return {
						// 解决 0 NAN%
						value: Math.abs(item.score == 0 ? 1 : item.score),
						name: item.category + "," + (item.score == 0 ? 1 : item.score),
					};
				}) : [];
				this.dataPie2 = datas;
				this.drawLine3();
			});
		},

		// 班级下查询学生
		searchparment() {
			api.getstudentID(this.did).then((res) => {
				this.studentList = res.data;
			});
			this.$nextTick(() => {
				// this.goSearchclass();
				this.goNewSearchclass();
			});
		},
		// 日
		getDataday() {
			let params = {
				starttime: this.searchDate != null && this.searchDate.length > 0 ?
					this.$moment(this.searchDate[0]).format("YYYY-MM-DD") : "",
				endtime: this.searchDate != null && this.searchDate.length > 0 ?
					this.$moment(this.searchDate[1]).format("YYYY-MM-DD") : "",
				classid: this.did,
				depid: this.depid,
			};
			// 读取 班级 折线图 数据
			api.classdayCid(params).then((res) => {
				let datas = res.data;
				let arr = [];
				for (let i = 0; i < datas.length; i++) {
					let line = (datas[i].type = "line");
					let dataList = (datas[i].data = datas[i].dataList);
					let dateList = (datas[i].xdate = datas[i].dateList);
					let obj = {
						type: line,
						data: dataList,
						xdate: dateList,
					};
					arr.push(obj);
				}
				let list = [];
				arr.forEach((item) => {
					list.push(item.name);
				});
				let xlist = [];
				arr.forEach((item) => {
					xlist.push(item.xdate);
				});
				this.dataline = arr; // 折线图数据
				this.namelist = list;
				this.xdatelist = xlist;
				this.drawLine1();
			});
		},
		// 周
		getDataweek() {
			let params = {
				starttime: "",
				endtime: "",
				classid: this.did,
				depid: this.depid,
			};
			api.classweekCid(params).then((res) => {
				let datas = res.data;
				let arr = [];
				for (let i = 0; i < datas.length; i++) {
					let line = (datas[i].type = "line");
					let dataList = (datas[i].data = datas[i].dataList);
					let dateList = (datas[i].xdate = datas[i].dateList);
					let obj = {
						type: line,
						data: dataList,
						xdate: dateList,
					};
					arr.push(obj);
				}
				let list = [];
				arr.forEach((item) => {
					list.push(item.name);
				});
				let xlist = [];
				arr.forEach((item) => {
					xlist.push(item.xdate);
				});
				this.dataline = arr;
				this.namelist = list;
				this.xdatelist = xlist;
				this.drawLine1();
			});
		},
		// 月
		getDatamonth() {
			let params = {
				starttime: "",
				endtime: "",
				classid: this.did,
				depid: this.depid,
			};
			api.classmonthCid(params).then((res) => {
				let datas = res.data;
				let arr = [];
				for (let i = 0; i < datas.length; i++) {
					let line = (datas[i].type = "line");
					let dataList = (datas[i].data = datas[i].dataList);
					let dateList = (datas[i].xdate = datas[i].dateList);
					let obj = {
						type: line,
						data: dataList,
						xdate: dateList,
					};
					arr.push(obj);
				}
				let list = [];
				arr.forEach((item) => {
					list.push(item.name);
				});
				let xlist = [];
				arr.forEach((item) => {
					xlist.push(item.xdate);
				});
				this.dataline = arr;
				this.namelist = list;
				this.xdatelist = xlist;
				this.drawLine1();
			});
		},
		pickchange(e) {
			this.searchDate = e;
		},
		// 切换tabs
		tabs(index) {
			this.current = index;
			if (this.current == 0) {
				this.getDataday();
				// this.goSearchclass();
				this.goNewSearchclass();
			} else if (this.current == 1) {
				this.getDataweek();
				// this.goSearchclass();
				this.goNewSearchclass();
			} else {
				this.getDatamonth();
				// this.goSearchclass();
				this.goNewSearchclass();
			}
		},
		// 所有班级
		changeSelect() {
			let params = {
				depid: this.depid,
			};
			api.getCardArgumentDetail(params).then((res) => {
				let arr = res.data.records;
				let arrclass = [];
				for (let i = 0; i < arr.length; i++) {
					if (arr[i].depid === this.depid) {
						arrclass.push(arr[i]);
					}
				}
				this.systemList = arrclass;
				this.getDataday();
				// this.goSearchclass();
				this.goNewSearchclass();
			});
		},
		loadData() {
			this.getType();
		},

		// 折线图
		drawLine1() {
			// 基于准备好的dom，初始化echarts实例
			var objTemp = document.getElementById("myChart2");
			// 先销毁
			// if (this.line_chart != null && this.line_chart != "" && this.line_chart != undefined) {
			//   this.line_chart.dispose(); // 销毁
			//   this.line_chart = null;
			// }
			// 再创建
			if (this.line_chart == null) {
				this.line_chart = echarts.init(objTemp);
			}
			// [{"type": "line",  "data": [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1.1, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ], "xdate":....
			if (this.dataline.length > 0 && this.dataline[0].data.length > 0) {
				this.ifShow_line_chart = true;
				// 绘制图表
				window.onresize = this.line_chart.resize;
				this.line_chart.setOption(this.getoption);
			} else {
				this.ifShow_line_chart = false;
			}
		},

		// -------------------- 饼图 --------------------
		drawLine2() {
			// 基于准备好的dom，初始化echarts实例
			var objTemp = document.getElementById("myChart3");
			// 先销毁
			// if (this.pie1_chart != null && this.pie1_chart != "" && this.pie1_chart != undefined) {
			//   this.pie1_chart.dispose(); // 销毁
			//   this.pie1_chart = null;
			// }
			// 再创建
			if (this.pie1_chart == null) {
				this.pie1_chart = echarts.init(objTemp);
			}
			// 绘制图表
			window.onresize = this.pie1_chart.resize;
			this.pie1_chart.setOption(this.getoption1);
		},
		drawLine3() {
			// 基于准备好的dom，初始化echarts实例
			var objTemp = document.getElementById("myChart4");
			// 先销毁
			// if (this.pie2_chart != null && this.pie2_chart != "" && this.pie2_chart != undefined) {
			//   this.pie2_chart.dispose(); // 销毁
			//   this.pie2_chart = null;
			// }
			// 再创建
			if (this.pie2_chart == null) {
				this.pie2_chart = echarts.init(objTemp);
			}
			// 绘制图表
			window.onresize = this.pie2_chart.resize;
			this.pie2_chart.setOption(this.getoption2);
		},

		addInfo() {
			this.dialogStatus = "create";
			this.dialogFormVisible = true;
		},
		handleDelete() { },
		handleView(row) {
			this.dialogStatus = "view";
			this.dialogFormVisible = true;
			this.form = row;
			this.$nextTick(() => {
				this.$refs.dialogForm.showData(this.form);
			});
		},
		indexMethod(index) {
			return index * 1;
		},
		changeDialogFormVisible(data) {
			this.dialogFormVisible = data;
			api.getUserList({
				page: 1,
				pageSize: 10,
				classnum: "",
			})
				.then((res) => {
					if (res.status == 1) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
		},
		// 通过token获取到该角色的身份信息
		getType() {
			api.getCurrUserItem().then((res) => (this.idis = res.data.rname));
		},
		handleClose(done) {
			this.$confirm("确认关闭？")
				.then(() => {
					done();
				})
				.catch(() => { });
		},
		handleEdit(row) {
			this.getRole();
			this.form.id = row.id;
			this.roles.roleName = row.role_name;
			this.dialogFormVisible = !this.dialogFormVisible;
		},
		// 查询
		goSearch() {
			if (!this.depid) {
				this.$message.error("请选择院系");
				return;
			}
			if (!this.did) {
				this.$message.error("请选择班级");
				return;
			}
			// 查询学生数据
			this.searchparment();
			let starttime = this.searchDate.length ? this.$moment(this.searchDate[0]).format("YYYY-MM-DD") : "";
			let endtime = this.searchDate.length ? this.$moment(this.searchDate[1]).format("YYYY-MM-DD") : "";
			let promiseList = [];
			this.studentList.forEach((item) => {
				let params = {
					starttime,
					endtime,
					studentid: item.id,
				};
				promiseList.push(api.addScore(params));
			});
			this.showWordCloud({
				startTime: starttime,
				endTime: endtime,
				depId: this.depid,
				classId: this.did,
			});
			this.$nextTick(() => {
				this.getDataday();
				// this.goSearchclass();
				this.goNewSearchclass();
				this.wordcloud();
			});
		},

		/**
		 * 导出Excel表格
		 */
		exportExcel() {
			let me = this;
			// 第一个引号里面是表格导出时的名字
			// 第二个是表格的id，这个插件是通过id导出的
			// me.outToExcel.exportExcel("班级数据.xlsx", "#vcfResult");

			api.exportSysData({ "mType": "mClass", "depid": me.did }).then((res) => {
				if (res.status == 1) {
					window.open(res.data, '_blank');
				} else {
					this.$message.error(res.msg);
				}
			});
		},
	},
};
</script>
<style lang="scss" scoped>
/deep/ .el-table {
	max-height: 338px;
	overflow-y: auto;
	border-bottom: 1px solid #eee;
}

.tabs {
	width: 200px;
	// background: red;
	margin: 20px auto;
	height: 40px;
	line-height: 40px;
	font-size: 20px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	justify-items: center;
	text-align: center;
	cursor: pointer;
}

.tabs div {
	width: 40px;
	height: 40px;
}

.active {
	background: #409eff;
	color: #fff;
}

/deep/ .el-container {
	width: 100%;
	// height: 100% !important;
}

.info {
	display: flex;

	span {
		line-height: 41px;
	}
}

.canvasBox {
	width: 100%;

	.test1 {
		width: 100%;
		height: 350px;

		div {
			width: 100%;
			height: 320px;
		}
	}

	.echarts-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: left;

		.echarts-pieChart {
			padding-top: 20px;
			width: 48%;
			height: 300px;
			border-top: 1px dashed #ddd;
			border-bottom: 1px dashed #ddd;

			&:nth-child(2n) {
				border-right: 1px dashed #ddd;
			}

			&:nth-child(2n + 1) {
				border-left: 1px dashed #ddd;
			}
		}
	}

}
</style>
